<template>
	<v-row>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-dialog v-model="dialogConfirmacao" persistent>
			<v-card>
				<v-card-title class="text-h5 pa-4">
					<span class="mx-auto">Confirmar a exclusão do contrato?</span>
				</v-card-title>
				<v-divider />
				<div class="text-center py-3">
					<v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
				</div>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn color="red" elevation="0" class="px-5" dark @click="dialogConfirmacao = false">Cancelar</v-btn>
					<v-btn color="primary" elevation="0" class="px-10" @click="deleteArquivo(diretorio)">Excluir</v-btn>
					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12" md="4">
			<v-card class="mx-auto">
				<v-card-title>Informações</v-card-title>
				<v-divider />
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text>
					<div class="my-3">
						<v-icon class="mr-2">mdi-identifier</v-icon>
						<span class="font-weight-bold">Nº contrato:</span>
						{{dados.idcontrato}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-account-outline</v-icon>
						<span class="font-weight-bold">Cliente:</span>
						{{dados.nome}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-office-building-outline</v-icon>
						<span class="font-weight-bold">Filial:</span>
						{{dados.filial}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-calendar</v-icon>
						<span class="font-weight-bold">Data pedido:</span>
						{{dados.dtcontrato | formataData}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-file-check-outline</v-icon>
						<span class="font-weight-bold">Status:</span>
						<span v-if="dados.status" class="text-truncate">
							<v-icon class="mx-1 mt-n1" color="success">mdi-check</v-icon>Contrato enviado
						</span>
						<span v-else-if="dados.encomenda" class="text-truncate">
							<v-icon class="mx-1 mt-n1" color="orange">mdi-check</v-icon>Liberado encomenda
						</span>
						<span v-else class="text-truncate">
							<v-icon class="mx-1 mt-n1" color="red">mdi-alert-circle-outline</v-icon>Contrato pendente
						</span>
					</div>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" md="8">
			<v-card color="primary" dark>
				<v-card-title>Contrato</v-card-title>
				<v-card-text>
					<div class="pa-1 white rounded" v-if="contratoPDF">
						<vue-pdf-embed :source="contratoPDF" />
					</div>
					<div class="pa-1 white rounded" v-else-if="imagens.length > 0">
						<v-carousel height="100%">
							<v-carousel-item
								v-for="(imagem,i) in imagens"
								:key="i"
								:src="imagem.arquivo"
								reverse-transition="fade-transition"
								transition="fade-transition"
							>
								<v-btn
									fab
									x-small
									color="error"
									class="float-end ma-1"
									@click="diretorio = imagem.diretorio, dialogConfirmacao = true"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</v-carousel-item>
						</v-carousel>
					</div>
					<template v-else>Nenhum documento encontrado</template>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
	components: { VuePdfEmbed, CardDialog },
	name: "ContratoGet",
	props: ["idcontrato"],
	data: () => ({
		carregando: false,
		dados: {},
		imagens: [],
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		dialogConfirmacao: false,
		contratoPDF: "",
		diretorio: "",
	}),
	computed: {
		...mapState(["backendUrl"]),
	},
	methods: {
		get() {
			this.carregando = true;
			this.imagens = [];
			return axios
				.post(`${this.backendUrl}contrato/get`, {
					idcontrato: parseInt(this.idcontrato),
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
					this.dados.diretorio.forEach((v) => {
						if (
							v.diretorio.split(".")[
								v.diretorio.split(".").length - 1
							] == "pdf"
						) {
							this.contratoPDF = v.diretorio;
							this.imagens = [];
							return;
						}
						this.getArquivo(v.diretorio);
					});
					//this.getArquivo(this.dados.diretorio[0].diretorio);
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		getArquivo(diretorio) {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}contrato/get/arquivo`, {
					diretorio: diretorio,
				})
				.then((res) => {
					this.imagens.push(res.data);
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		deleteArquivo(diretorio) {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}contrato/delete/arquivo`, {
					diretorio: diretorio,
				})
				.then((res) => {
					this.dialogConfirmacao = false;
					this.carregando = false;
					if (res.data) {
						this.dialogErro.title = "Contrato excluído";
						this.dialogErro.msg = "Contrato excluído com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg =
							"Não foi possível excluir o contrato!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
					this.imagens = [];
					this.get();
				})
				.catch(() => {
					this.dialogConfirmacao = false;
					this.carregando = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível excluir o contrato!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
					this.get();
				});
		},
	},
	created() {
		this.get();
	},
};
</script>

<style scoped>
</style>